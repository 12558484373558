<template>
    <div>
        <div class="header">
			<div class="logo Title">

				<div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
						@click="$router.back()" />珍宝联名

				</div>
			</div>
			<div class="right-icon">
				<img src="../assets/img/logo-icon.png" alt="">
				<div class="cc-wc">
					{{ $t('main.titleCenter') }}
				</div>
			</div>
		</div>
    
        <div class="unit_page">
            <div class="unit_t">珍宝联名</div>
            <div class="unit_t1">碰撞智慧的火花</div>
            <div class="unit_info">
                <div class="info_title">
                    <img src="../assets/img/ui_wj.png" alt="">
                    珍宝信息
                </div>
                <div class="goods">
                    <img :src="picture" alt="">
                    <div class="goods_info">
                        <div class="goods_name">{{name}}</div>
                        <div class="goods_info_items">
                            <img src="../assets/img/qk.png" alt="">
                            珍宝编码：{{serial_number}}
                        </div>
                        <div class="goods_info_items">
                            <img src="../assets/img/dj.png" alt="">
                            珍宝等级：{{level}}
                        </div>
                        <div class="goods_info_items">
                            <img src="../assets/img/jz.png" alt="">
                            市场估值：{{price}}
                        </div>
                    </div>
                </div>
                <div class="info_btn" @click="showqrcode">发起联名</div>
            </div>
            <div class="unit_apply">
                <div class="unit_title">
                    <img src="../assets/img/ui_lt.png" alt="">
                    联名申请
                    <div class="unit_title_r" @click="getApplylist">
                        <img class="unit_re" src="../assets/img/ui_re.png" alt="">
                        <span >刷新</span>
                    </div>
                    
                </div>
                <div class="apply_art">
                    <div class="app_items" v-for="(item,index) in list" :key="index" >
                        <img :src="item.apply_artist.pic" alt="">
                        <div class="art_info">
                            <div class="art_name">{{item.apply_artist.name}}</div>
                            <div class="app_time">{{item.apply_at}}</div>
                        </div>
                        <div class="status">
                            <div class="applying" v-show="item.status == 0" @click="Confirm_app(item)" >确认</div>
                            <div class="success" v-show="item.status == 1">已同意</div>
                            <div class="fail" v-show="item.status == 2">已拒绝</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="model" v-show="isTran">
                <div class="unit_pop">
                    <div class="pop_title">
                        <div >
                            <img src="../assets/img/ui_logo.png" alt="">
                            珍宝联名流程
                        </div>
                        <img @click="closeTran" src="../assets/img/close_white.png" alt="">
                    </div>
                    <div class="pop_frist">
                        授权其他作者联名您的珍宝，即名义上与您共同拥有珍宝，该珍宝由您创建，由您主导。
                    </div>
                    <div class="xh">
                        <div><div></div></div>
                        <div>01</div>
                    </div>
                    <div class="xh_next">
                        让联名作者用微信扫一扫以下二维码
                    </div>
                    <div class="qrcode" id="qrcode">

                    </div>
                    <div class="xh">
                        <div><div></div></div>
                        <div>02</div>
                    </div>
                    <div class="xh_next">联名者在扫码打开的申请页面中点击"提交申请"按钮</div>
                    <div class="exmample_btn">提交申请</div>
                    <div class="xh">
                        <div><div></div></div>
                        <div>03</div>
                    </div>
                    <div class="xh_next">点击联名申请的刷新按钮，获取最新申请，点击申请右侧的"确认"按钮，在弹窗中点击"确认并同意联名"按钮即可授权对应作者联名当前珍宝</div>
                    <div class="exmample_img">
                        <img src="../assets/img/example_re.png" alt="">
                        <img src="../assets/img/example_co.png" alt="">
                    </div>
                    <div>授权其他作者联名您的珍宝后，您仍可编辑或转让珍宝，参与联名的作者仅可落款签名和下载珍宝证书</div>
                    <div class="border1"></div>
                    <div class="border2"></div>
                    <div class="border3"></div>
                </div>
            </div>
            <div class="modal" v-show="isConfirm" @click="isConfirm = false" >
                <div class="send"  @click.stop>
                    <div class="title">联名作者确认</div>
                    <div class="avatar">
                        <img :src="avatar" alt="">
                    </div>
                    <div class="name">{{name}}</div>
                    <div style="text-align:center;font-size:0.13rem">
                        同意联名申请后将授权该作者联名您<br/>
                        当前的珍宝，此操作无法撤回。
                    </div>
                    <div class="button" @click="Con_send(1)" >确认并同意联名</div>
                    <div class="btnn" @click="Con_send(2)" >拒绝联名</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
    data(){
        return{
            avatar:'',
            name:'',
            isConfirm:false,
            isTran:false,
            list:[],
            qrcode:'',
            name:'',
            picture:'',
            price:'',
            serial_number:'',
            level:'',
            hascode:false,
            app_id:0
        }
    },
    methods:{
        async getCode(){
            await this.axios.get(`goods/getInviteLinkCode?goods_id=${this.$route.query.id}&artist_type=1`).then((res)=>{
                this.qrcode = res.data.data.qrCode
            })
        },
        getApplylist(){
            this.axios.get('goods/getGoodsLinkArtistList?goods_id='+this.$route.query.id+'&artist_type=1').then((res)=>{
                this.list = res.data.data?.list || []
                // let list = []
                // if(JSON.stringify(res.data.data) != "{}"){
                //     list.push({
                //         id:res.data.data.id,
                //         apply_artist:res.data.data.apply_artist,
                //         apply_at:res.data.data.apply_at,
                //         status:res.data.data.status
                //     })
                //     this.list = list
                // }
                
            })
        },
        async showqrcode(){
            await this.getCode()
            let qrcode = document.getElementById('qrcode')
            qrcode.innerHTML = ""
            let url = location.protocol + '//' + location.host
            new QRCode(qrcode, {
                text: url+'/api/auth/login?url=' + url + '/unit_info?qrCode=' + this.qrcode, //要生成二维码的网址
                width: 256, //图像宽度
                height: 256, //图像高度
                colorDark: "#000000", //前景色
                colorLight: "#ffffff", //背景色
                margin: 0, //外边距
                correctLevel: QRCode.CorrectLevel.L, //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
            });
            this.hascode = true
            this.isTran = true
        },
        closeTran(){
            this.isTran = false
        },
        Confirm_app(item){
            this.avatar = item.apply_artist.pic
            this.name = item.apply_artist.name
            this.app_id = item.id
            this.isConfirm = true
        },
        Con_send(status){
            this.axios.post('goods/setLinkApplyBuy',{id:this.app_id,status,artist_type:1}).then((res)=>{
                if(res.data.code == 0){
                    this.isConfirm  = false
                    weui.alert(status == 1?'已同意联名':'已拒绝联名')
                    this.getApplylist()
                }else{
                    weui.alert(res.data.msg)
                }
                
            })
        }
    },
    mounted(){
        this.getApplylist()
        this.name = this.$route.query.name
        this.picture = this.$route.query.pictures
        this.price = this.$route.query.price
        this.serial_number = this.$route.query.serial_number
        this.level = this.$route.query.level
    }
}
</script>

<style>
.unit_page{
    background: url(../assets/img/unit_top.png) no-repeat;
    background-size: 100% auto;
    background-position: 0 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 0.6rem);
}
.unit_t{
    margin-left: 0.51rem;
    font-size: 0.225rem;
    background: linear-gradient(to bottom,#fdf9e7,#e2b26d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin-top: 0.16rem;
}
.unit_t1{
    margin-top: 0.1rem;
    margin-left: 0.51rem;
    font-size: 0.12rem;
    color: #fff;
}
.unit_info{
    width: 3.45rem;
    margin: 0 auto;
    margin-top: 0.19rem;
    background: url(../assets/img/unit_goods-min.jpg) no-repeat;
    height: 2.4rem;
    background-size: 100% 100%;
    border-radius: 0.1rem;
    box-sizing: border-box;
    padding: 0.15rem;
}
.info_title{
    display: flex;
    font-size: 0.17rem;
    color: #333333;
    align-items: center;
}
.info_title img{
    width: 0.165rem;
    height: 0.14rem;
    margin-right: 0.12rem;
}
.goods{
    height: 1.25rem;
    width: 100%;
    display: flex;
    align-items: center;
}
.goods>img{
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
    object-fit: contain;
}
.goods_info{
    height: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.goods_name{
    font-size: 0.15rem;
    color: #333333;
    height: 0.4rem;
    line-height: 0.2rem;
    text-overflow: ellipsis;
    overflow: hidden;
}
.goods_info_items{
    color: #525252;
    font-size: 0.13rem;
    display: flex;
    align-items: center;
}
.goods_info_items img{
    height: 0.1rem;
    object-fit: contain;
    width: 0.13rem;
    margin-right: 0.1rem;
}
.info_btn{
    width: 2.5rem;
    height: 0.35rem;
    background: url(../assets/img/view-more.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.14rem;
    color: #333333;
    margin: 0 auto;
    margin-top: 0.15rem;
}
.unit_apply{
    margin-top: 0.15rem;
    background: url(../assets/img/unit_artist-min.jpg) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    min-height: 3.2rem;
    flex: 1;
    border-radius: 0.1rem 0.1rem 0 0;
    box-sizing: border-box;
    padding: 0.15rem;
    
    display: flex;
    flex-direction: column;
}
.unit_title{
    flex-shrink: 0;
    display: flex;
    align-items: center;
}
.apply_art{
    margin-top: 0.24rem;
    flex: 1;
    overflow-y: auto;
}
.app_items{
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    padding: 0 0.15rem;
    height: 0.75rem;
    border-radius: 0.05rem;
    margin-bottom: 0.1rem;
}
.app_items img{
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-right: 0.1rem;
}
.art_info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 0.5rem;
    color: #333333;
}
.art_name{
    font-size: 0.15rem;
}
.app_time{
    font-size: 0.13rem;
}
.status{
    margin-left: auto;
}
.status > div{
    width: 0.8rem;
    height: 0.35rem;
    border-radius: 0.175rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.15rem;
}
.applying{
    color: #fff;
    background: #972c2c;
}
.success{
    color: #972c2c;
}
.fail{
    color: #929292;
}
.model{
    position: fixed;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
}
.modal{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
}
.unit_pop{ 
    position: relative;
    height: 100%;
    overflow-y: auto;
    background: url(../assets/img/new_form_bg.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    box-sizing: border-box;
    padding: 0.15rem;
    font-size: 0.13rem;
}
.xh{
    display: flex;
    align-items: center;
}
.xh > div:nth-child(1){
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #f3e19e,#d89555);
    margin-right: 0.1rem;
}
.xh > div:nth-child(1) > div{
    width: 0.08rem;
    height: 0.08rem;
    border-radius: 50%;
    background: #601925;
}
.xh > div:nth-child(2){
    background: linear-gradient(to bottom, #f3e19e,#d89555);
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0,0,0,0);
    font-size: 0.18rem;
    font-weight: bold;
    font-style: italic;
}
.xh_next{
    margin-left: 0.2rem;
    font-size: 0.13rem;
    color: #fff;
}
.pop_title{
    display: flex;
    align-items: center;
}
.pop_frist{
    margin: 0.15rem 0.05rem;
}
.exmample_btn{
    width: 2.5rem;
    height: 0.35rem;
    background: url(../assets/img/view-more.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.14rem;
    color: #333333;
    margin: 0 auto;
    margin-top: 0.15rem;
}
.exmample_img{
    display: flex;
    align-items: center;
    margin-left: 0.2rem;
    margin-top: 0.17rem;
    margin-bottom: 0.45rem;
    width: 3.2rem;
    justify-content: space-between;
}
.exmample_img img{
    width: 1.55rem;
    height: 0.57rem;
    border-radius: 0.08rem;
}
.qrcode{
    height: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.qrcode canvas{
    width: 1.8rem !important;
    height: 1.8rem !important;
}
.qrcode img{
    width: 1.8rem !important;
    height: 1.8rem !important;
    padding: 0.1rem;
    background: #fff;
    box-sizing: border-box;
}
.border1{
    position: absolute;
    top: 1.6rem;
    left: 0.2rem;
    height: 1.9rem;
    width: 1px;
    background: #b2867b;
}
.border2{
    position: absolute;
    top: 4rem;
    left: 0.2rem;
    height: 0.8rem;
    width: 1px;
    background: #b2867b;
}
.border3{
    position: absolute;
    top: 5.2rem;
    left: 0.2rem;
    height: 1.6rem;
    width: 1px;
    background: #b2867b;
}
.modal .send{
    width: 3rem;
    height: 3.85rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.1rem;
    background: url(../assets/img/data_scan_bg.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
}
.send .title{
    font-size: 0.15rem;
    margin-top: 0.23rem;
    margin-bottom: 0.23rem;
}
.send .avatar{
    width: 0.84rem;
    height: 0.84rem;
    background: url(../assets/img/data_avatar_bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.send .avatar img{
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
}
.send .name{
    margin-top: 0.17rem;
    margin-bottom: 0.2rem;
    font-size: 0.15rem;
    color: #feedcd;
}
.send .button{
    margin-top: 0.2rem;
    width: 2rem;
    height: 0.32rem;
    background: url(../assets/img/view-more.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.14rem;
    color: #333;
}
.btnn{
    margin-top: 0.17rem;
    width: 2rem;
    height: 0.32rem;
     display: flex;
    justify-content: center;
    align-items: center;
    color: #ffedaa;
    font-size: 0.14rem;
    border-radius: 0.02rem;
    background: #c3585d;
    box-shadow: inset 0 0 0 1px #ffedaa;
}
.unit_title > img{
    width: 0.19rem;
    height: 0.19rem;
    margin-right: 0.15rem;
}
.unit_title_r{
    margin-left: auto;
    display: flex;
    align-items: center;
}
.unit_title .unit_re{
    
    margin-right: 0.08rem;
    width: 0.16rem;
    height: 0.16rem;
}
.unit_title span{
    color: #972c2c;
}
.pop_title>div:nth-child(1){
    display: flex;
    align-items: center;
}
.pop_title>div:nth-child(1) img{
    width: 0.2rem;
    height: 0.15rem;
    margin-right: 0.08rem;
}
.pop_title > img{
    margin-left: auto;
    width: 0.2rem;
    height: 0.2rem;
}

.header {
	display: flex;
	justify-content: space-between;
	padding: 0 0.15rem;
	align-items: center;
	box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.3);
	padding-bottom: 0.09rem;
	padding-top: 0.05rem;
	background: #ffffff;
    box-sizing: border-box;
}

.Title {
	font-size: 0.16rem;
	position: relative;
}

.English {
	color: #d6eedc;
	position: absolute;
	top: 0.1rem;
	left: 0.15rem;
}

.Chinese {
	color: #1b6a21;
	/* top: -0.2rem; */
	left: 0;
	position: relative;
	text-align: left;
	font-size: 0.18rem;
	white-space: nowrap;
}

.cc-wc {
	font-size: 0.1rem;
	color: #e9630a;
}

.right-icon {
	/* width: 1rem; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.right-icon img {
	display: block;
	width: 100%;
	height: 100%;
	width: 0.65rem;
}
</style>